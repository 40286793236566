import React from "react";
import { Link } from "gatsby";
import Spiral from "../../assets/img/svg/spiral.svg";
import Track from "../../assets/img/svg/track.svg";
import Custom from "../../assets/img/svg/custom.svg";
import Cable from "../../assets/img/svg/cable.svg";
import Auth from "../../assets/img/svg/auth.svg";

import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

import "./style.scss";

const CategoryButtons = ({ titlePage }) => {
  const CATEGORY = [
    {
      title: "Strumento di marketing",
      className: "floatA",
      filename: Spiral,
      to: "/strumento-di-marketing",
    },
    {
      title: "Tracciabilità dei dati",
      className: "floatB",
      filename: Track,
      to: "/tracciabilità-dei-dati",
    },
    {
      title: "Personalizzazione",
      className: "floatC",
      filename: Custom,
      to: "/personalizzazione",
    },
    {
      title: "Modalità di autenticazione",
      className: "floatD",
      filename: Auth,
      to: "/modalità-di-autenticazione",
    },
    {
      title: "Plug&Play",
      className: "floatE",
      filename: Cable,
      to: "/plug-and-play",
    },
  ];

  return (
    <>
      {titlePage ? (
        <div className="container">
          <div className="columns categoryBtns">
            {CATEGORY.filter((i) => i.title !== titlePage).map((i, index) => (
              <div className="column" key={index}>
                <Link to={i.to}>
                  <div className="categoryBtn">
                    <div className="backgroundBtn">
                      <p>{i.title}</p>
                      <Controller>
                        <Scene
                          offset={0}
                          duration="200%"
                          triggerHook="onEnter"
                        >
                          <Timeline wrapper={<div className="parallax" />}>
                            <Tween
                              position="0"
                              from={{
                                top: "100%",
                                scale: 0.5,
                              }}
                              to={{
                                top: "-70%",
                                scale: 1,
                              }}
                            >
                              <img className={i.className} src={i.filename} alt={i.title} />
                            </Tween>
                          </Timeline>
                        </Scene>
                        <div className="section" />
                      </Controller>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      ) : (        
        <div className="columns categoryBtns">
          {CATEGORY.map((i, index) => (
            <div className="column" key={index}>
              <Link to={i.to}>
                <div className="categoryBtn ">
                  <div className="backgroundBtn">
                    <p>{i.title}</p>
                    <Controller>
                        <Scene
                          offset={0}
                          duration="200%"
                          triggerHook="onEnter"
                        >
                          <Timeline wrapper={<div className="parallax" />}>
                            <Tween
                              position="0"
                              from={{
                                top: "140%",
                                scale: 0.5,
                              }}
                              to={{
                                top: "-90%",
                                scale: 1,
                              }}
                            >
                              <img className={i.className} src={i.filename} alt={i.title} />
                            </Tween>
                          </Timeline>
                        </Scene>
                        <div className="section" />
                      </Controller>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CategoryButtons;
