import React from "react";
import Header from "../components/header/header.js";
import CategoryButtons from "../components/categoryButtons/categoryButtons";
import Custom from "../assets/img/svg/custom.svg";

const Customization = () => {
  return (
    <>
      <Header
        title="Personalizzazione"
        description={`È un software completamente personalizzabile, sia a livello di contenuti che grafico.`}
        categoryIcon={{ icon: Custom, alt: "Personalizzazione Banner" }}
        metaDescription="È un software completamente personalizzabile, sia a livello di contenuti che grafico."
      />
      <div className="container">
      <div className="columns">
        <div className="column is-7">
          <h2>
            Un perfetto tailoring su misura per la promozione della tua attività.
          </h2>
          <p className="description">
            <br />
            <br />
            Scegli di customizzare il nostro captive portal attraverso la
            personalizzazione della landing page con piattaforme di marketing
            automation, engagement diretti, upselling, e comunque tutto ciò che
            possa essere inserito o integrato all'interno di un comune CSS per
            la programmazione della landing page di autenticazione e benvenuto.
            <br />
            <br />
            Attraverso le azioni di Branding e Bannering, potrai promuovere il
            tuo marchio e la tua attività, ma anche le attività di terzi e/o
            partner.
            <br />
            <br /> Inoltre, potrai scegliere su quale piattaforma far atterrare
            l’utente una volta autenticatosi. Il redirect potrà avvenire su
            profili social, business, welcome page dedicate.
          </p>
        </div>
      </div>
      </div>
      
      <div className="section" />
      <div className="container">
        <div className="columns">
          <div className="column">
            <h2>Come può aiutarti Connect@You?</h2>
          </div>
          <div className="column">
            <p className="description">
              Fai sentire il tuo cliente a casa sua, grazie alla familiarità
              della lingua, alla semplicità e fruibilità del sistema di
              autenticazione.
              <br />
              <br />              
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <p className="description">
              Sistema multilingue
              <br />
              <br />
              Coccola il tuo cliente e fallo sentire subito a casa sua, grazie
              al nostro servizio di autenticazione che gli consentirà di
              visualizzare direttamente la landing page nella lingua impostata
              sul suo device.
              <br />
              <br />
              <br />
              Responsive design
              <br/>
              <br/>
              Permette di essere correttamente visualizzato da qualsiasi device
              (PC, smartphone o tablet) ed è supportato da sistemi Android, IOS.
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <p className="center">Ti potrebbe interessare...</p>
        </div>
      </div>
      <div className="section" />
      <CategoryButtons titlePage="Personalizzazione" />
    </>
  );
};

export default Customization;
